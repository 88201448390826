import React from 'react'
import { LimitType } from '../../../types/pagination-interface'
import { ACTION_KEY } from '../constants'
import { ColumnDefinitionInterface } from '../types/column-interface'

interface TableSkeletonProps<T> {
  columns: ColumnDefinitionInterface<T>[]
  totalWidth: number
  limit?: LimitType
}

export function TableSkeleton<T>({ columns, totalWidth, limit }: TableSkeletonProps<T>) {
  return (
    <>
      {Array.from(Array(limit || 10).keys()).map((row, index) => (
        <div
          key={`row-${index}`}
          className={`flex animate-pulse flex-col gap-4 border border-transparent lg:gap-0 lg:flex-row items-start lg:items-center even:bg-blue-100 odd:bg-white group px-6 py-4 lg:p-0 ${
            index === 9 && 'rounded-b-lg'
          }`}
        >
          {columns.map((column, index2) => {
            if (!column.widthPercent) column.widthPercent = 25
            const widthPercent = column.widthPercent || 25
            return (
              <div
                className={`lg:px-6 lg:py-4 ${
                  column.key === ACTION_KEY ? 'w-[42px] lg:w-[90px]' : `w-full`
                }`}
                key={`cell-${index2}`}
                style={{
                  flex: `${column.key !== ACTION_KEY && `${(widthPercent / totalWidth) * 100}`}`,
                }}
              >
                <div className="bg-gray-600 rounded text-transparent">.</div>
              </div>
            )
          })}
        </div>
      ))}
    </>
  )
}
