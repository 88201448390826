import { Menu } from '@headlessui/react'
import React from 'react'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'

interface ActionMenuLinkItemProps {
  isNext?: boolean
  href: string
  label: string | JSX.Element
  openInNew?: boolean
  dataTestAttributes?: Record<string, string>
}

export const ActionMenuLinkItem = ({
  dataTestAttributes,
  label,
  openInNew,
  isNext,
  href,
}: ActionMenuLinkItemProps) => (
  <Menu.Item key={'action-edit'}>
    {({ active }) =>
      isNext ? (
        <LinkWithoutPrefetch
          aria-pressed={active}
          type="button"
          className={`group aria-pressed:text-blue flex items-center gap-2 w-full px-4 py-3 lg:py-2 text-left text-sm main-transition-colors border-b border-gray-200 last:border-0`}
          href={href}
          {...dataTestAttributes}
        >
          <span>{label}</span>
        </LinkWithoutPrefetch>
      ) : (
        <a
          aria-pressed={active}
          type="button"
          className={`group aria-pressed:text-blue flex items-center gap-2 w-full px-4 py-3 lg:py-2 text-left text-sm main-transition-colors border-b border-gray-200 last:border-0`}
          href={href}
          target={openInNew ? '_blank' : '_self'}
          rel={'noreferrer'}
          {...dataTestAttributes}
        >
          <span>{label}</span>
        </a>
      )
    }
  </Menu.Item>
)
