import React, { ReactNode } from 'react'
import EmptyList from 'shared/components/table/components/empty-list'
import { InstanceForPaginationType } from 'shared/components/table/types/instance-interface'
import { ACTION_KEY } from '../constants'
import { BaseTableProps } from './base-table'
import TableRow from './table-row'
import { TableSkeleton } from './table-skeleton'

export interface TableRowsProps<T>
  extends Pick<
    BaseTableProps<T>,
    | 'data'
    | 'columns'
    | 'testAttributePostfix'
    | 'type'
    | 'noHeader'
    | 'firstLineRect'
    | 'emptyListWithTitles'
    | 'onRowClick'
    | 'nestedId'
    | 'paginationIdField'
    | 'limiter'
    | 'withBorders'
    | 'isSortable'
  > {
  totalWidth: number
  className?: string | ((instance: T) => string)
}

export function TableRows<T extends InstanceForPaginationType<T>>({
  data,
  columns,
  type,
  testAttributePostfix,
  totalWidth,
  noHeader,
  firstLineRect,
  onRowClick,
  emptyListWithTitles,
  nestedId,
  paginationIdField,
  limiter,
  withBorders,
  isSortable,
  className,
}: TableRowsProps<T>) {
  if (data === undefined) {
    return <TableSkeleton limit={limiter?.limit} columns={columns} totalWidth={totalWidth} />
  } else {
    return emptyListWithTitles && data.items.length === 0 ? (
      <div className={'bg-white'}>
        <EmptyList />
      </div>
    ) : (
      <div data-test="table-rows">
        {data.items.map((row, index) => (
          <TableRow
            key={`row-${JSON.stringify(row)}`}
            data={data}
            testAttributePostfix={testAttributePostfix}
            noHeader={noHeader}
            firstLineRect={firstLineRect}
            onRowClick={onRowClick}
            nestedId={nestedId}
            paginationIdField={paginationIdField}
            withBorders={withBorders}
            row={row}
            index={index}
            isSortable={isSortable}
            className={className}
            data-test={`table-row-${index}`}
          >
            {columns.map((column, index2) => {
              if (!column.widthPercent && column.key !== ACTION_KEY) column.widthPercent = 25
              const keys = (column.key as string).split('.')
              let content: T[keyof T] | T | undefined = undefined
              keys.forEach(key => {
                content = !!content ? (content as T)[key as keyof T] : row[key as keyof T]
              })
              const onRenderSmall = column.onRenderSmall
                ? column.onRenderSmall(row)
                : column.onRender
                ? column.onRender(row)
                : content

              const onRender = column.onRender ? column.onRender(row) : content
              const node = (
                <>
                  <div
                    className={`flex lg:hidden items-center flex-wrap ${
                      column.key !== ACTION_KEY && 'gap-3'
                    } ${column.className || ''}`}
                  >
                    {column.onRenderSmall ? (
                      onRenderSmall
                    ) : (
                      <>
                        <span className="text-darkblue font-bold whitespace-nowrap">
                          {column.header}
                        </span>
                        {onRender}
                      </>
                    )}
                  </div>

                  <div className={'hidden lg:block'}>{onRender}</div>
                </>
              )
              return (
                <div
                  key={`cell-${index2}`}
                  className={`lg:px-6 ${type === 'large' ? 'lg:py-4' : 'lg:py-2.5'} ${
                    column.key === ACTION_KEY ? 'w-full lg:w-0' : 'w-full truncate'
                  } `}
                  style={{
                    width: `${column.key === ACTION_KEY ? 'fit-content' : ''}`,
                    flex: `${
                      column.key === ACTION_KEY
                        ? '0'
                        : `${(column.widthPercent! / totalWidth) * 100}`
                    }`,
                  }}
                >
                  {node as ReactNode}
                </div>
              )
            })}
          </TableRow>
        ))}
      </div>
    )
  }
}
