import React from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import EmptyListIcon from 'shared/icons/empty-list-icon'

interface EmptyListProps {
  small?: boolean
  firstLineRect?: boolean
  footerAdditionalElement?: JSX.Element
}

const EmptyList = ({ small, firstLineRect, footerAdditionalElement }: EmptyListProps) => {
  const { t } = useLocoTranslation()
  return (
    <>
      <div
        className={`flex w-full justify-center items-center flex-col gap-3 ${
          small ? 'py-10' : 'py-20'
        } bg-white ${firstLineRect ? 'rounded-b-lg' : 'rounded-lg'}`}
      >
        <EmptyListIcon className={small ? 'w-32 h-32' : ''} />
        <div className={`flex flex-col items-center`}>
          <span className={'text-darkblue text-base'}>{t('dashboard.list.empty_title')}</span>
          <span className={'text-darkblue text-base'}>{t('dashboard.list.empty_list')}</span>
        </div>
      </div>
      {footerAdditionalElement && (
        <div className={'mt-3 flex justify-start'}>{footerAdditionalElement}</div>
      )}
    </>
  )
}

export default EmptyList
