import React, { useRef } from 'react'
import EmptyList from 'shared/components/table/components/empty-list'
import { InstanceForPaginationType } from 'shared/components/table/types/instance-interface'
import {
  DataWithPaginationInterface,
  LimitType,
  PaginationInterface,
} from 'shared/types/pagination-interface'
import { twJoin } from 'tailwind-merge'
import { ColumnDefinitionInterface } from '../types/column-interface'
import { calculateTableTotalWidth } from '../utils'
import { TableHeader } from './table-header'
import TableLimitPagination from './table-limit-pagination'
import { TableRows } from './table-rows'

export interface BaseTableProps<T> {
  data: DataWithPaginationInterface<T> | undefined
  nestedId?: keyof T
  paginationIdField?: { [K in keyof T]: T[K] extends string | number ? K : never }[keyof T]
  type?: 'large' | 'small'
  columns: ColumnDefinitionInterface<T>[]
  limiter?: {
    limit: LimitType
    setLimit: React.Dispatch<React.SetStateAction<LimitType>>
  }
  paginator?: {
    pagination: PaginationInterface
    setPagination: React.Dispatch<React.SetStateAction<PaginationInterface>>
  }
  testAttributePostfix?: string
  noHeader?: boolean
  firstLineRect?: boolean
  emptyListWithTitles?: boolean
  onRowClick?: (instance: T) => void
  withBorders?: boolean
  isSortable?: boolean
  footerAdditionalElement?: JSX.Element
  rowClassName?: string | ((instance: T) => string)
}

export function BaseTable<T extends InstanceForPaginationType<T>>({
  data,
  nestedId,
  paginationIdField,
  type = 'large',
  columns,
  limiter,
  paginator,
  testAttributePostfix,
  noHeader,
  firstLineRect,
  onRowClick,
  emptyListWithTitles,
  withBorders,
  isSortable,
  footerAdditionalElement,
  rowClassName,
}: BaseTableProps<T>) {
  const totalWidth = calculateTableTotalWidth(columns)

  const tableRef = useRef<HTMLDivElement>(null)

  return !data ||
    data.items.length !== 0 ||
    emptyListWithTitles ||
    paginator?.pagination.startFromId ? (
    <div className="flex flex-col gap-3 w-full" ref={tableRef} data-test="table">
      <div
        className={`overflow-x-hidden ${firstLineRect ? 'rounded-b-lg' : 'rounded-lg'} ${
          withBorders && 'border border-darkblue border-opacity-[0.15]'
        }`}
        data-test-table-is-loaded={!!data}
      >
        {!noHeader && <TableHeader columns={columns} totalWidth={totalWidth} />}
        <TableRows
          limiter={limiter}
          nestedId={nestedId}
          paginationIdField={paginationIdField}
          onRowClick={onRowClick}
          totalWidth={totalWidth}
          type={type}
          data={data}
          columns={columns}
          testAttributePostfix={testAttributePostfix}
          noHeader={noHeader}
          firstLineRect={firstLineRect}
          emptyListWithTitles={emptyListWithTitles}
          withBorders={withBorders}
          isSortable={isSortable}
          className={rowClassName}
        />
      </div>
      <div className={twJoin('flex', footerAdditionalElement ? 'justify-between' : 'justify-end')}>
        {footerAdditionalElement}
        {paginator && limiter && (
          <TableLimitPagination
            tableRef={tableRef}
            nestedId={nestedId}
            paginationIdField={paginationIdField}
            paginator={paginator}
            limiter={limiter}
            data={data}
          />
        )}
      </div>
    </div>
  ) : (
    <EmptyList footerAdditionalElement={footerAdditionalElement} />
  )
}
