import { AnimateLayoutChanges, defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { twMerge } from 'tailwind-merge'
import {
  InstanceForPaginationType,
  InstanceWithIdType,
  InstanceWithNestedIdType,
} from '../types/instance-interface'
import { TableRowsProps } from './table-rows'

export type TableRowProps<T extends InstanceForPaginationType<T>> = React.PropsWithChildren<
  Pick<
    TableRowsProps<T>,
    | 'data'
    | 'testAttributePostfix'
    | 'noHeader'
    | 'firstLineRect'
    | 'onRowClick'
    | 'nestedId'
    | 'paginationIdField'
    | 'withBorders'
    | 'isSortable'
    | 'className'
  > & {
    row: T
    index?: number
  } & Omit<React.HTMLProps<HTMLDivElement>, 'data' | 'className'>
>

const animateLayoutChanges: AnimateLayoutChanges = args =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true })

const TableRow = <T extends InstanceForPaginationType<T>>({
  data,
  testAttributePostfix,
  noHeader,
  firstLineRect,
  onRowClick,
  nestedId,
  paginationIdField,
  withBorders,
  row,
  index,
  className,
  children,
  style,
  isSortable,
  ...props
}: TableRowProps<T>) => {
  const id = paginationIdField
    ? row[paginationIdField]
    : nestedId
    ? (row[nestedId] as InstanceWithNestedIdType<any>).id
    : (row as InstanceWithIdType).id
  const { newIndex, attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
    id,
    animateLayoutChanges,
    disabled: !isSortable,
  })

  const rowIndex = newIndex !== -1 ? newIndex : index

  const dndStyle = {
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.5 : undefined,
  }

  const receivedClassName = typeof className === 'string' ? className : className?.(row)

  return (
    <div
      ref={setNodeRef}
      style={{ ...dndStyle, ...style }}
      {...attributes}
      {...listeners}
      onClick={e => {
        const target = e.target as HTMLDivElement
        const button = target.closest('button')
        const link = target.closest('a')
        const modalBackground = target.closest('#modal-background')
        if (button || link || modalBackground) return
        if (onRowClick) {
          onRowClick(row)
        }
      }}
      className={twMerge(
        'flex flex-col px-6 py-4 lg:p-0 border hover:border-blue main-transition-colors gap-4 lg:gap-0 lg:flex-row items-start lg:items-center even:bg-blue-100 odd:bg-white',
        rowIndex === 0 &&
          !firstLineRect &&
          (noHeader ? 'rounded-t-lg' : 'rounded-t-lg lg:rounded-t-none'),
        rowIndex === (data?.items.length || 0) - 1 && 'rounded-b-lg',
        withBorders && 'border border-b-darkblue border-opacity-[0.15] last:border-b-transparent',
        isDragging ? ' border-blue border-dashed' : 'border-transparent ',
        isSortable ? 'cursor-grab' : onRowClick ? 'cursor-pointer' : 'cursor-auto',
        receivedClassName,
      )}
      {...(testAttributePostfix
        ? {
            ['data-test-id']: `${testAttributePostfix}-item-${id}`,
          }
        : {})}
      {...props}
    >
      {children}
    </div>
  )
}

export default TableRow
